<template>
  <b-overlay
    :show="loading"

    spinner-variant="primary"
  >
    <b-card>
      <div class="">
        <b-row v-if="error_message">
          <b-col>
            <small
              class="text-danger d-block mb-1"
            >
              {{ error_message }}
            </small>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Тип"
              label-for="type"
            >
              <b-form-input
                id="type"
                :value="'Внешний'"
                placeholder="Введите название"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Режим работы"
              label-for="mode"
            >
              <v-select
                id="select-mode"
                v-model.trim="item.mode"
                placeholder="Режим работы"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="siteModes"
                :reduce="val => val.value"
                :clearable="false"
                input-id="mode"
                label="name"
                :class="{
                  'is-invalid': !!get(validation, 'mode[0]')
                }"
              />
              <small
                v-if="!!get(validation, 'mode[0]')"
                class="text-danger"
              >
                {{ get(validation, 'mode[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Название *"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model.trim="item.name"
                placeholder="Введите название"
                :state="get(validation, 'name[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'name[0]')"
                class="text-danger"
              >
                {{ get(validation, 'name[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Описание"
              label-for="description"
            >
              <!--              v-mask="{ regex: '^[a-z0-9]+(?:-[a-z0-9]+)*$' }"-->
              <b-form-textarea
                id="description"
                v-model.trim="item.description"
                placeholder="Введите описание"
                :state="get(validation, 'description[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'description[0]')"
                class="text-danger"
              >
                {{ get(validation, 'description[0]') }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col>
            <b-button
              variant="primary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="createItem"
            >
              Создать
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BButton, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import get from 'lodash/get'
import { getCurrentInstance } from 'vue'
import useCrudCreate from '@/composables/useCrudCreate'
import { createWebsite } from '@/services/main-api/websites/externals/websites'
import siteModes from '@/modules/sites-section/sites/composables/siteModes'

export default {
  name: 'CreateWarehouseView',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    BFormTextarea,
    vSelect,
  },
  setup() {
    const instance = getCurrentInstance().proxy

    const createEntity = async () => {
      await createWebsite({
        project_id: instance.$store.getters['workingMode/selected_project_id'],
        name: instance.item.name,
        description: instance.item.description,
        mode: instance.item.mode,
      })
    }

    const {
      item,
      loading,
      validation,
      error_message,
      checkValidation,
      clearForm,
      catchValidation,
      createItem,
    } = useCrudCreate({
      item: {
        id: null,
        name: '',
        mode: siteModes[0].value,
        description: '',
      },
      validation: {
        rules: {
          name: 'required',
        },
        customErrorMessages: {
          'required.name': 'Укажите название',
        },
      },
      createEntity,

      getTextAfterCreation: () => 'Сайт был создан.',
      runAfterCreation: async () => {
        await instance.$router.push({ name: 'sites' })
      },
    })

    return {
      item,
      loading,
      validation,
      error_message,
      siteModes,

      checkValidation,
      clearForm,
      catchValidation,
      createItem,
      get,
    }
  },
  mounted() {
    if (!this.$store.getters['workingMode/mode']) {
      this.$router.push({ name: 'dashboard' })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
